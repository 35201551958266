/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import styled from 'styled-components'
import CallToAction, { StyledCallToAction } from '../components/CallToAction'
import { breakpoints, colors, layout } from '../theme'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const StyledCgvPage = styled.section`
  .page-content {
    margin-top: ${layout.navbarHeight.normal}px;
  }

  @media (max-width: ${breakpoints.mobile}px) {
    .page-content {
      margin-top: ${layout.navbarHeight.mobile}px;
    }
  }
  ${StyledCallToAction} {
    margin-bottom: 50px;
    height: 350px;

    .wrapper {
      max-width: ${layout.maxWidth}px;
      width: ${layout.Layout};
      margin: 50px auto;
      text-align: center;

      h1 {
        max-width: 800px;
      }
    }
  }

  .wrapper-legal {
    max-width: ${layout.maxWidth}px;
    width: ${layout.width};
    margin: 0 auto;
    margin-bottom: 50px;

    h3 {
      color: ${colors.brand.normal};
      padding: 5px 0;
      border-radius: 50px;
      margin-top: 50px;
      margin-bottom: 15px;
      font-size: 1.2rem;
    }
    p {
      margin: 5px 0;
    }
  }

  @media only screen and (max-width: 768px) {
    .wrapper {
      padding: 0;
    }
  }
`

class CgvPage extends React.Component {
  render() {
    return (
      <StyledCgvPage>
        <Navbar />
        <div className="page-content">
          <CallToAction
            text={`
                Mentions Légales
              `}
            backgroundColor={`rgb(248, 226, 191)`}
            buttonText={null}
            className="header-cta"
          />
          <div className="wrapper-legal">
            <h3>Edition du site internet</h3>
            <p>
              Le site{' '}
              <a
                href="www.monbanquet.fr"
                rel="noopener noreferrer"
                title="Le site"
              >
                www.monbanquet.fr
              </a>{' '}
              (ci-après "le Site") est édité par la société JEG (ci-après
              "l'Editeur"), SAS au capital de 15 000 euros, dont le siège social
              est situé 23 avenue René Cassin 77127 LIEUSAINT.
              <br />
              RCS 803 062 611 R.C.S Melun
              <br />
              TVA intracommunautaire n° FR 09 803 062 611
              <br />
            </p>

            <h3>Rédaction du site internet</h3>
            <p>
              Directeur de la publication : Juliette GROUT
              <br />
              Contact : contact@monbanquet.fr
            </p>

            <h3>Rédaction des mentions légales</h3>
            <p>
              Dénomination sociale : Captain Contrat <br />
              Adresse siège social : 28 rue des petites écuries, 75010 Paris{' '}
              <br />
              Site internet :{' '}
              <a
                href="https://captaincontrat.com"
                rel="noopener noreferrer nofollow"
                title="Rédigez vos mentions légales gratuitement sur Captain Contrat"
              >
                www.captaincontrat.com
              </a>
            </p>

            <h3>Hébergeur du site internet</h3>
            <p>
              Le site www.monbanquet.fr est hébergé par Netlify, Inc., dont le
              siège social est situé au 2325 3rd Street, Suite 296, San
              Francisco, California 94107. Les données sont hébergées en Irlande
              chez Amazon Web Service EMEA SARL, 31 PLACE DES COROLLES, TOUR
              CARPE DIEM, 92400 COURBEVOIE, FRANCE SIREN : 831 001 334 RCS
              Nanterre.
            </p>

            <h3>Collectes des données</h3>
            <p>N° de déclaration CNIL : 18109666</p>

            <p>
              Les informations personnelles pouvant être recueillies sur le Site
              sont principalement utilisées par l'Editeur pour la gestion des
              relations avec vous, et le cas échéant pour le traitement de vos
              commandes. Elles sont enregistrées dans le fichier de clients de
              l'Editeur, et le fichier ainsi élaboré à partir de données à
              caractère personnel est déclaré auprès de la CNIL.
            </p>

            <p>
              Conformément aux dispositions de la loi n° 78-17 du 6 janvier 1978
              modifiée, relative à l'informatique, aux fichiers et aux libertés,
              vous êtes informé(e) que l'Editeur procède à des traitements
              automatisés de vos données personnelles, notamment lors de votre
              connexion au Site ou dans le cadre des formulaires en ligne
              remplis par vos soins (numéro de déclaration CNIL 18109666). Vous
              disposez d'un droit d'accès, d'interrogation, de modification et
              de suppression des informations qui vous concernent, à exercer à
              tout moment auprès de l'Editeur soit directement sur le Site à la
              rubrique "Contactez-nous" par courrier postal à l'adresse suivante
              : 32 rue des Palétuviers.
            </p>

            <p>
              Les informations recueillies pourront éventuellement être
              communiquées à des tiers liés à l'Editeur par contrat pour
              l'exécution de tâches sous-traitées nécessaires à la gestion de
              votre compte et sans que vous ayez à donner votre autorisation.
              Vous pouvez vous opposer à ce transfert en manifestant votre refus
              auprès de notre contact mentionné ci-dessus.
            </p>

            <p>
              En cas d'infraction avérée à des dispositions légales ou
              réglementaires, ces informations pourront faire l'objet d'une
              communication sur demande expresse et motivée des autorités
              judiciaires.
              <br />
              Lorsque certaines informations sont obligatoires pour accéder à
              des fonctionnalités spécifiques du Site, l'Editeur indiquera ce
              caractère obligatoire au moment de la saisie des données.
            </p>

            <h3>Cookies</h3>
            <p>
              Le Site peut collecter automatiquement des informations standards
              telles que INFORMATIONS COLLECTEES. Toutes les informations
              collectées indirectement ne seront utilisées que pour suivre le
              volume, le type et la configuration du trafic utilisant ce Site,
              pour en développer la conception et l'agencement et à d'autres
              fins administratives et de planification et plus généralement pour
              améliorer le service que nous vous offrons. Vous avez la
              possibilité de visiter le Site en désactivant cette option dans
              les paramètres de votre navigateur.
            </p>

            <p>
              Certains cookies que nous émettons sont fournis par des
              prestataires et partenaires tiers. Pour en savoir plus à propos
              des pratiques de Visiblee et pour exercer votre choix quant à ces
              cookies, vous pouvez cliquer sur le lien suivant :
              http://confidentiality.visiblee.io/fr/confidentialite
            </p>

            <p>
              Vous pouvez désactiver les cookies en modifiant les paramètres de
              votre navigateur. Vous pouvez aussi à tout moment supprimer toutes
              vos données ou annuler la collecte et le stockage des données par
              Visiblee pour la période qui suit le moment de l'annulation, en
              complétant le formulaire sur cette page :
              http://confidentiality.visiblee.io/fr/fulldesabo
            </p>

            <h3>Respect de la propriété intellectuelle</h3>
            <p>
              Toutes les marques, photographies, textes, commentaires,
              illustrations, images animées ou non, séquences vidéo, sons, ainsi
              que toutes les applications informatiques qui pourraient être
              utilisés pour faire fonctionner le Site et plus généralement tous
              les éléments reproduits ou utilisés sur le Site sont protégés par
              les lois en vigueur au titre de la propriété intellectuelle.
            </p>
            <p>
              Ils sont la propriété pleine et entière de l'Editeur ou de ses
              partenaires, sauf mentions particulières. Toute reproduction,
              représentation, utilisation ou adaptation, sous quelque forme que
              ce soit, de tout ou partie de ces éléments, y compris les
              applications informatiques, sans l'accord préalable et écrit de
              l'Editeur, sont strictement interdites. Le fait pour l'Editeur de
              ne pas engager de procédure dès la prise de connaissance de ces
              utilisations non autorisées ne vaut pas acceptation desdites
              utilisations et renonciation aux poursuites.
            </p>
            <p>
              Seule l'utilisation pour un usage privé dans un cercle de famille
              est autorisée et toute autre utilisation est constitutive de
              contrefaçon et/ou d'atteinte aux droits voisins, sanctionnées par
              Code de la propriété intellectuelle.
            </p>
            <p>
              La reprise de tout ou partie de ce contenu nécessite
              l'autorisation préalable de l'Editeur ou du titulaire des droits
              sur ce contenu.
            </p>

            <h3>Liens hypertextes</h3>
            <p>
              Le Site peut contenir des liens hypertexte donnant accès à
              d'autres sites web édités et gérés par des tiers et non par
              l'Editeur. L'Editeur ne pourra être tenu responsable directement
              ou indirectement dans le cas où lesdits sites tiers ne
              respecteraient pas les dispositions légales.
              <br />
              La création de liens hypertexte vers le Site ne peut être faite
              qu'avec l'autorisation écrite et préalable de l'Editeur.
            </p>
            <br />
            <p>
              Ces mentions légales ont été rédigées gratuitement sur le site
              <a
                href="https://captaincontrat.com"
                rel="noopener noreferrer"
                title="Rédigez vos mentions légales gratuitement sur Captain Contrat"
              >
                {' '}
                www.captaincontrat.com
              </a>
            </p>
          </div>
        </div>
        <Footer />
      </StyledCgvPage>
    )
  }
}

export default CgvPage
