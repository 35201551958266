import { graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { breakpoints, colors } from '../theme'
import { Button, StyledButton } from '@monbanquet/crumble'

const CallToAction = ({
  text = 'Votre événement sur mesure',
  subText,
  picture,
  buttonText = 'Demander un devis',
  ctaClicked = () => {},
  ...props
}) => {
  if (subText) {
    subText = typeof subText === 'string' ? subText : subText.subText
  }

  return (
    <StyledCallToAction {...props} hasPicture={!!picture}>
      <div className="wrapper">
        <div className="text-wrapper">
          <span className="title">{text}</span>
          {buttonText && <Button onClick={ctaClicked}>{buttonText}</Button>}
          {subText && <p dangerouslySetInnerHTML={{ __html: subText }}></p>}
        </div>
      </div>
    </StyledCallToAction>
  )
}

const StyledCallToAction = styled.section`
  height: 100%;
  display: flex;
  align-items: center;
  color: ${colors.text.dark};
  background-color: ${colors.background.semiLight};
  overflow: hidden;

  .wrapper {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }

  span.title {
    color: ${colors.brand.black};
    font-size: 37px;
    line-height: 47px;
    max-width: 700px;
    margin: auto;
    font-family: Bely;
    display: block;
  }

  p {
    font-size: 1.2rem;
    margin-top: 30px;
    line-height: 1.9rem;
  }

  ${StyledButton} {
    margin-top: 30px;
    background-color: ${colors.secondary.yellow};
    color: ${colors.brand.black};
    height: 66px;
    font-size: 19px;
    padding: 0 64px;
    text-transform: none;
  }

  .picture {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    width: 100%;
    overflow: hidden;
    align-self: center;
  }

  @media (max-width: ${breakpoints.desktop}px) {
    .text-wrapper span.title {
      font-size: 2rem;
      line-height: 3rem;
    }
  }

  @media (max-width: ${breakpoints.tablet}px) {
    .text-wrapper {
      span.title {
        font-size: 2rem;
        line-height: 3rem;
      }
    }
    height: 500px;
  }

  @media (max-width: ${breakpoints.mobile}px) {
    height: 450px;
    padding: 0;
    .text-wrapper {
      text-align: center;
      align-items: center;
      width: 100%;

      span.title {
        font-size: 26px;
      }

      ${StyledButton} {
        height: 47px;
        font-size: 14px;
        padding: 15px 50px;
      }
    }
    .picture {
      display: none;
    }
  }
`

export default CallToAction
export { StyledCallToAction }

export const CallToActionFragment = graphql`
  fragment CallToActionFragment on ContentfulCallToAction {
    text
    subText {
      subText
    }
    buttonText
    backgroundColor
    picture {
      title
      fluid {
        aspectRatio
        src
        srcSet
        sizes
        srcWebp
        srcSetWebp
      }
    }
  }
`
